/*
 * custom-theme-color2.less
 * -----------------------------------------------
*/
.team-block .team-thumb .styled-icons a:hover,
.work-gallery .gallery-thumb .styled-icons a:hover,
.work-gallery:hover .gallery-bottom-part,
.double-line-bottom-theme-colored2:after,
.double-line-bottom-theme-colored2:before,
.double-line-bottom-centered-theme-colored2:after,
.double-line-bottom-centered-theme-colored2:before,
.circled-line-centered-theme-colored2:before,
.circled-line-left-theme-colored2:before,
.line-bottom-theme-colored2:after,
.line-bottom-centered::after,
.line-bottom-rounded-theme-colored2:after,
.line-bottom-double-line-centered::before,
.line-bottom-double-line-centered::after,
.event-block .event-date,
.team-social,
.event-small .event-date,
.portfolio-filter a.active,
.title-border::after,
.owl-theme .owl-dots .owl-dot.active span,
.icon-box.icon-filled:hover i,
.post .post-thumb .post-date,
.sidebar .widget .widget-title::after,
.contact-info-box i {
  background: @theme-color2;
}
.testimonial-content::after,
.top-course-thumb .desc-box .off,
.breadcrumb.white li.active,
.widget .post-title a:hover {
    color:@theme-color2;
}
.footer a:focus, .footer a:hover, .footer a:active {
    color: @theme-color2;
}
.about-video:hover .video-button {
    background-color: fade(@theme-color2, 90%);
}
.event-grid .thumb .entry-date{
    background-color: fade(@theme-color2, 90%);
}
.btn-theme-colored2 {
  background-color: @theme-color2;
}
.review-stars i {
  color: @theme-color2;
}
.price-tag,
.widget .tags a:hover {
  background: @theme-color2;
}
.services-list li.active a {
  background-color: @theme-color2 !important;
}
.services-list ul li a:hover {
    background-color: @theme-color2;
    color: @white-base;
}
.services-list li.active a {
    border: 1px solid @theme-color2;
}
blockquote.theme-colored {
  background: #f7f7f7 none repeat scroll 0 0;
  border-left: 3px solid @theme-color2;
}
.brochured li a i {
    color: @theme-color2;
}
.star-rating span::before {
  color: @theme-color2 !important;
}

.nav-tabs > li.active a, .nav-tabs > li.active a:hover, .nav-tabs > li.active a:focus {
  color: @theme-color2;
}
.nav-tabs > li > a:hover {
  color: @theme-color2;
  border-bottom: 1px solid @theme-color2;
}

.progress-item .progress-bar {
    background-color: @theme-color2;
}
.play-btn {
  box-shadow: 2px 1px 23px 5px @theme-color2;
}
.feature-item .thumb .title {
	border-top: 2px solid @theme-color2;
}
.circled-line-centered-theme-colored2::before,
.circled-line-left-theme-colored2::before {
	box-shadow: 0 0 3px 3px fade(@theme-color2, 40%);
}
.circled-line-centered-theme-colored2:after,
.circled-line-left-theme-colored2:after {
	border: 1px solid @theme-color2;
}
.border-left-theme-colored2,
.team-thumb h4 {
	border-left: 4px solid @theme-color2;
}
.border-bottom-theme-colored2{
	border-bottom: 2px solid @theme-color2;
}
.border-bottom-theme-colored2-1px {
	border-bottom: 1px solid @theme-color2;
}